import React from "react"
import Loadable from "@loadable/component"

const VideoConferenceClient = Loadable(() => import("../components/video-conference/zoom"), {
  ssr: false,
})

const ZoomVideoClient = () => {
  return <VideoConferenceClient />
}

export default ZoomVideoClient
